<template>
	<div class="schemeFunction">
		<div class="title">方案功能</div>
		<img class="schemeFunctionEn" src="./assets/schemeFunction.png" />
		<div class="navBox">
			<div class="bj"></div>
			<div class="left" @click="() => { swiper2.slidePrev() }">
				<svg t="1636098415988" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1313" width="12" height="12" fill=""><path d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z" p-id="1314"></path></svg>
			</div>
			<div id="navSwiper" ref="navSwiper" class="schemeFunctionNav swiper-container ">
				<div class="top swiper-wrapper">
					<div class="swiper-slide" v-for="(item, index) in showlist" :key="'nav'+index" @click="schemeNavChange(index)">
						<div style="display:inline-block" >
							<span >{{item.title}}</span>
							<div :style="{ width: schemeFunctionNavActive == index ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<!-- <div class="swiper-slide" @click="schemeNavChange(0)">
						<div style="display:inline-block" >
							⽣产管理
							<div :style="{ width: schemeFunctionNavActive == '0' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(1)">
						<div style="display:inline-block" >
							⼯艺管理
							<div :style="{ width: schemeFunctionNavActive == '1' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(2)">
						<div  style="display:inline-block">
							可视化⼤屏管理
							<div :style="{ width: schemeFunctionNavActive == '2' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(3)">
						<div  style="display:inline-block">
							物料管理
							<div :style="{ width: schemeFunctionNavActive == '3' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(4)">
						<div  style="display:inline-block">
							快反管理
							<div :style="{ width: schemeFunctionNavActive == '4' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(5)">
						<div  style="display:inline-block">
							质量管理
							<div :style="{ width: schemeFunctionNavActive == '5' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(6)">
						<div  style="display:inline-block">
							设备管理
							<div :style="{ width: schemeFunctionNavActive == '6' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(7)">
						<div  style="display:inline-block">
							项目管理
							<div :style="{ width: schemeFunctionNavActive == '7' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div>
					<div class="swiper-slide" @click="schemeNavChange(8)">
						<div  style="display:inline-block">
							Andon管理
							<div :style="{ width: schemeFunctionNavActive == '8' ? '100%' : 0 }" class="active" >
								<div class="line"></div>
								<img class="sub" src="./assets/sub.png" />
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="right" @click="() => { swiper2.slideNext() }">
				<svg t="1636098924307" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2107" width="12" height="12"><path d="M761.055557 532.128047c0.512619-0.992555 1.343475-1.823411 1.792447-2.848649 8.800538-18.304636 5.919204-40.703346-9.664077-55.424808L399.935923 139.743798c-19.264507-18.208305-49.631179-17.344765-67.872168 1.888778-18.208305 19.264507-17.375729 49.631179 1.888778 67.872168l316.960409 299.839269L335.199677 813.631716c-19.071845 18.399247-19.648112 48.767639-1.247144 67.872168 9.407768 9.791372 21.984142 14.688778 34.560516 14.688778 12.000108 0 24.000215-4.479398 33.311652-13.439914l350.048434-337.375729c0.672598-0.672598 0.927187-1.599785 1.599785-2.303346 0.512619-0.479935 1.056202-0.832576 1.567101-1.343475C757.759656 538.879828 759.199462 535.391265 761.055557 532.128047z" p-id="2108"></path></svg>
			</div>
		</div>
		<div id="swiper" class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide schemeFunctionLi" v-for="(item, index) in showlist" :key="'swiper'+index">
					<div class="describe">
						<div class="title">{{item.title}}</div>
						<div class="info">
							{{item.info}}
						</div>
						<img :src="item.imgx" />
					</div>
					<img class="preview" :src="item.imgd" />
				</div>
				<!-- <div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">生产管理</div>
						<div class="info">
							针对车间的派单、报工、维修、装配、收发货等进行现场生产管理，同时汇集各类生产数据，生成相应生产报表，包括生产指标、生产量、生产计划等等。
						</div>
						<img src="./assets/生产管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/生产管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">⼯艺管理</div>
						<div class="info">
							MES系统能快速获取相应⼯艺参数、⼯时定额、⼯艺路线等数据,为计划排产、物料拉动、成本分析等提供数据⽀持。
						</div>
						<img src="./assets/⼯艺管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/⼯艺管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">可视化⼤屏管理</div>
						<div class="info">
							MES系统根据需求对不同部⻔、不同⼯⼚、不同产线、不同⼯位制作可视化⼤屏展示，快速直观了解⽣产信息。
						</div>
						<img src="./assets/可视化⼤屏管理X@2x.png" />
					</div>
					<img
						class="preview"
						src="./assets/可视化⼤屏管理D@2x.png"
					/>
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">物料管理</div>
						<div class="info">
							MES系统內建⽴多级物料拉动机制,结合客户实际⽣产模式，定制化建设物料JIT、JIS等拉动系统，有效降低库存⽔平，减低物料积压⻛险。
						</div>
						<img src="./assets/物料管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/物料管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">快反管理</div>
						<div class="info">
							根据企业需求，快速反馈来料、⽣产、检验、销售、使⽤全过程在定期审核排查情况，确保安全问题及时响应，及时处理。
						</div>
						<img src="./assets/快反管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/快反管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">质量管理</div>
						<div class="info">
							通过对产品⽣产全过程监控，提供给质检⼈员相关的基础数据和分析⼯具。对⼯艺过程的合法性，产品率、不良缺陷分布的波动状况进⾏实时监控并预警，对产线上的问题进⾏了有效预防提供。
						</div>
						<img src="./assets/质量管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/质量管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">设备管理</div>
						<div class="info">
							通过⼈⼯录⼊和线体集成采集的设备基础数据,对设备的状态进⾏管理,对设备保养、故障维护、改造、报废等使⽤过程的管理,同时对备品备件管理,并能够进⾏各种统计分析。
						</div>
						<img src="./assets/设备管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/设备管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">项目管理</div>
						<div class="info">
							系统化精确管理项⽬任务，实现订单计划情况和⽣产进度的实时更新关联，快速监控项⽬进度，及时预警，辅助排期。
						</div>
						<img src="./assets/项目管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/项目管理D@2x.png" />
				</div>
				<div class="swiper-slide schemeFunctionLi">
					<div class="describe">
						<div class="title">Andon管理</div>
						<div class="info">
							精益制造执行的核心工具，能快速识别响应异常，进行复停工操作，并联络生产、维修、物料等部门，实现生产透明管理，记录精准的生产数据。
						</div>
						<img src="./assets/Andon管理X@2x.png" />
					</div>
					<img class="preview" src="./assets/Andon管理D@2x.png" />
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import Swiper from 'swiper';
import img1x from './assets/生产管理X@2x.png'
import img1d from './assets/生产管理D@2x.png'
import img2x from './assets/⼯艺管理X@2x.png'
import img2d from './assets/⼯艺管理D@2x.png'
import img3x from './assets/可视化⼤屏管理X@2x.png'
import img3d from './assets/可视化⼤屏管理D@2x.png'
import img4x from './assets/物料管理X@2x.png'
import img4d from './assets/物料管理D@2x.png'
import img5x from './assets/快反管理X@2x.png'
import img5d from './assets/快反管理D@2x.png'
import img6x from './assets/质量管理X@2x.png'
import img6d from './assets/质量管理D@2x.png'
import img7x from './assets/设备管理X@2x.png'
import img7d from './assets/设备管理D@2x.png'
import img8x from './assets/项目管理X@2x.png'
import img8d from './assets/项目管理D@2x.png'
import img9x from './assets/Andon管理X@2x.png'
import img9d from './assets/Andon管理D@2x.png'
export default {
	props: {
		show: {
			type: [Array, String],
			default: '1,2,3,4,5,6,7,8,9'
		}
	},
  data() {
    return {
      schemeFunctionNavWidth: [],
      schemeFunctionNavLeft: [],
      schemeFunctionNavActive: null,
      swiper: null,
			swiper2: null,
			list: [
				{
					title: '生产管理',
					info: '针对车间的派单、报工、维修、装配、收发货等进行现场生产管理，同时汇集各类生产数据，生成相应生产报表，包括生产指标、生产量、生产计划等等。',
					imgx: img1x,
					imgd: img1d
				},
				{
					title: '工艺管理',
					info: 'MES系统能快速获取相应工艺参数、工时定额、工艺路线等数据,为计划排产、物料拉动、成本分析等提供数据支持。',
					imgx: img2x,
					imgd: img2d
				},
				{
					title: '可视化大屏管理',
					info: 'MES系统根据需求对不同部门、不同工厂、不同产线、不同工位制作可视化大屏展示，快速直观了解生产信息。',
					imgx: img3x,
					imgd: img3d
				},
				{
					title: '物料管理',
					info: 'MES系统內建立多级物料拉动机制,结合客户实际生产模式，定制化建设物料JIT、JIS等拉动系统，有效降低库存水平，减低物料积压风险。',
					imgx: img4x,
					imgd: img4d
				},
				{
					title: '快反管理',
					info: '根据企业需求，快速反馈来料、生产、检验、销售、使用全过程在定期审核排查情况，确保安全问题及时响应，及时处理。',
					imgx: img5x,
					imgd: img5d
				},
				{
					title: '质量管理',
					info: '通过对产品生产全过程监控，提供给质检人员相关的基础数据和分析工具，。对工艺过程的合法性，产品率、不良缺陷分布的波动状况进行实时监控并预警，对产线上的问题进行了有效预防提供。',
					imgx: img6x,
					imgd: img6d
				},
				{
					title: '设备管理',
					info: '通过人工录入和线体集成采集的设备基础数据,对设备的状态进行管理,对设备保养、故障维护、改造、报废等使用过程的管理,同时对备品备件管理,并能够进行各种统计分析。',
					imgx: img7x,
					imgd: img7d
				},
				{
					title: '项目管理',
					info: '系统化精确管理项目任务，实现订单计划情况和生产进度的实时更新关联，快速监控项目进度，及时预警，辅助排期。',
					imgx: img8x,
					imgd: img8d
				},
				{
					title: 'Andon管理',
					info: '精益制造执行的核心工具，能快速识别响应异常，进行复停工操作，并联络生产、维修、物料等部门，实现生产透明管理，记录精准的生产数据。',
					imgx: img9x,
					imgd: img9d
				}
			],
			navTextHeight: 40
    }
  },
	destroyed() {
		this.swiper = null
		this.swiper2 = null
	},
	mounted() {
		this.carInit();
	},
	created() {
		let data = [];
		const list = [];
		if (!Array.isArray(this.show)) data = this.show.split(',');
		else data = this.show;
		data.forEach(item => {
			list.push(this.list[--item])
		})
		this.showlist = list
	},
  methods: {
    carInit() {
			const num = parseInt(this.$refs.navSwiper.clientWidth / 170);
      this.swiper = new Swiper ('#swiper', {
        loop: true,
        touchRatio : 0
      })
			this.swiper2 = new Swiper('#navSwiper', {
				slidesPerView: num
			})
			this.$nextTick(() => {
				this.schemeFunctionNavActive = 0;
			})

    },
    schemeNavChange(index) {
      this.schemeFunctionNavActive = index;
      this.swiper.slideTo(index + 1);
    },
  }
};
</script>

<style lang="less" scoped>
.schemeFunction {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.schemeFunctionEn {
		width: 436px;
		transform: translateY(-21px);
	}
	.schemeFunctionNav {
		width: 1190px;
		.top {
			div {
				cursor: pointer;
				span {
					font-size: 22px;
				}
			}
		}
		.active {
			width: 100%;
			position: relative;
			transition: all 0.5s;
			margin: 0 auto;
			.line {
				width: 100%;
				height: 4px;
				background: #3a9eff;
			}
			.sub {
				width: 27.6%;
				max-width: 26px;
				margin: 0 auto;
				transform: translateY(10px);
			}
		}
	}
	.navBox {
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
		width: 1240px;
		position: relative;
		.left,.right {
			width: 50px;
			height: 40px;
			display: flex;
			align-items: center;
			transform: translateY(-3px);
			justify-content: center;
			.icon {
				width: 26px;
				height: 26px;
				fill: #A1B4C8;
				cursor: pointer;
			}
			.icon:hover {
				fill: #293957;
			}
		}
		span {
			display: block;
			height: 40px;
			line-height: 40px;
		}
		.bj {
			position: absolute;
			width: 1920px;
			height: 170px;
			background: linear-gradient(to bottom, #E3F2FF, #ffffff);
			left: -339px;
			top: 40px;
			z-index: -1;
		}
	}
	.schemeFunctionLi {
		width: 1240px;
		padding: 70px 0 100px;
		display: flex;
		justify-content: space-between;
		.describe {
			width: 480px;
			text-align: left;
			.title {
				font-size: 36px;
			}
			.info {
				margin-top: 30px;
				font-size: 18px;
				line-height: 2;
			}
			img {
				margin-top: 100px;
				width: 466px;
			}
		}
		.preview {
			width: 609px;
		}
	}
}
</style>
