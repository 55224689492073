<template>
	<div>
		<div class="explain">
			<div class="title">
				电子组装行业
			</div>
			<div class="info">
				<p>灵妙电子组装行业覆盖了产品从来料到生产制造的全过程，优化了产品制造过程信息的传递过程，<br> 实现了ERP系统、PDM系统、MES系统、WMS系统、QMS系统等纵向层次之间的数字化信息集成，形成制造决策、执行和控制等信息流的闭环。<br> 推动提高企业高效、高产、高质量发展水平。</p>
			</div>
		</div>
		<div class="painPoints">
			<div class="title">行业痛点</div>
			<img class="painPointsEn" src="../assets/painPoints.png" />
			<div class="painPointsCards">
				<img src="./assets/T1.png" />
				<div class="card">
					<div class="title">质量管控困难</div>
					<div class="info">
						订单内容多且杂，工艺制作标准不一，人工错件、漏件频发，质量问题追溯难；
					</div>
				</div>
				<img src="./assets/T2.png" />
				<div class="card">
					<div class="title">计划执行不同步</div>
					<div class="info">
						生产工艺频繁变动，纸质或电话共同不明确，信息滞后造成生产停滞。
					</div>
				</div>
				<div class="card">
					<div class="title">绩效考核难统计</div>
					<div class="info">
						计件工资核算审核难，财务纸质核对计算，人工成本较高还易出错。
					</div>
				</div>
				<img src="./assets/T3.png" />
				<div class="card">
					<div class="title">系统独立不流通</div>
					<div class="info">
						系统间协作效率低，信息不流通，生产问题反馈不及时。
					</div>
				</div>
				<img src="./assets/T4.png" />
			</div>
		</div>
		<div class="advantages">
			<div class="title">方案优势</div>
			<img class="advantagesEn" src="../assets/advantages.png" />
			<div class="advantagesCards">
				<div class="card">
					<div class="text">
						<div class="title">全生命周期追溯</div>
						<div class="info">
							从来料、生产、检验、销售、使用全过程的质量追溯，确保每项审核、每个问题有记录、有反馈、可查询。
						</div>
					</div>
					<img src="./assets/advantagesImg1.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">运营过程透明化</div>
						<div class="info">
							实时统计分析生产制造过程核心指标参数，实现运营全过程透明化、智能化管理，为高效率、高质量的智能管理与决策提供支持。
						</div>
					</div>
					<img src="./assets/advantagesImg2.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">全面协同制造</div>
						<div class="info">
							通过系统集成，促进实现部门对内部门、对外经销商、供应商、物流承运商的全面制造协同。
						</div>
					</div>
					<img src="./assets/advantagesImg3.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">高效绩效考核</div>
						<div class="info">
							通过电子化绩效考核，快速统计生产件数，关联到人、到工位，为绩效分析提供数据支持
						</div>
					</div>
					<img src="./assets/advantagesImg4.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.explain {
	padding-top: 111px;
	width: 1920px;
	height: 442px;
	box-sizing: border-box;
	background: #f3f8fc;
	line-height: 1;
	.title {
		font-size: 42px;
	}
	.info {
		width: 1240px;
		margin: 30px auto 0;
		line-height: 2;
		color: #666666;
		font-size: 16px;
	}
	img {
		width: 848px;
		margin: 0 auto;
		transform: translateY(105px);
	}
}
.painPoints {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.painPointsEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.painPointsCards {
		width: 1240px;
		margin: 80px auto 0;
		display: flex;
		flex-wrap: wrap;
		.card {
			width: 310px;
			height: 310px;
			box-sizing: border-box;
			padding: 40px;
			text-align: left;
			background: #97AC0B;
			.title {
				font-size: 22px;
				font-weight: bold;
				color: #ffffff;
				line-height: 1;
				margin-bottom: 30px;
			}
			.info {
				font-size: 18px;
				color: #ffffff;
				line-height: 2;
			}
		}
		.card:nth-of-type(2) {
			background: #AB7F5D;
		}
		.card:nth-of-type(3) {
			background: #4A4281;
		}
		.card:nth-of-type(4) {
			background: #218AEF;
		}
		img {
			width: 310px;
		}
	}
}
.advantages {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.advantagesEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.advantagesCards {
		padding-top: 60px;
		.card {
			padding: 90px;
			display: flex;
			justify-content: space-between;
			position: relative;
			border-top: 1px /*no*/ solid #d5dfe8;
			&:last-of-type {
				border-bottom: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd) {
				border-right: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(even) {
				border-left: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/rightTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				right: -1px;
			}
			&:nth-of-type(even)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/leftTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				left: -1px;
			}
			// &:last-of-type::after {
			//   content: '';
			//   width: 1240px;
			//   height: 1px;/*no*/
			//   background: #D5DFE8;
			//   position: absolute;
			//   bottom: 0;
			//   left: 0;
			// }
			.text {
				padding-top: 43px;
				width: 617px;
				text-align: left;
				.title {
					font-size: 36px;
					line-height: 1;
					margin-bottom: 40px;
				}
				.info {
					font-size: 18px;
					color: #666666;
					line-height: 2;
					display: -webkit-box;
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
				}
			}
			img {
				width: 353px;
			}
		}
		.card:nth-child(even) {
			flex-direction: row-reverse;
		}
		.shadow {
			width: 1240px;
			height: 60px;
			background: linear-gradient(0deg, #ffffff, #f3f8fc);
		}
	}
}
</style>
