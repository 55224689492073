<template>
	<div>
		<div class="explain">
			<div class="title">汽车零部件制造行业</div>
			<div class="info">
				<p>
					汽车零部件制造行业解决方案是将灵妙自主的信息化平台技术、数字化智慧管理与汽车零部件制造行业的先进制造技术进行深度融合，<br />
					实现机械制造产品营销、研发、制造、服务等全生命周期的信息化管理，推动提高企业高效、高产、高质量发展水平，<br />
					实现汽车零部件制造的数字化、网络化、智能化。
				</p>
			</div>
		</div>
		<div class="painPoints">
			<div class="title">行业痛点</div>
			<img class="painPointsEn" src="../assets/painPoints.png" />
			<div class="painPointsCards">
				<img src="./assets/T1.png" />
				<div class="card">
					<div class="title">物料跟踪不透明</div>
					<div class="info">
						⽣产进度与原材料及成品收发脱节，缺乏对供应商产能的把控，物料仓储难管理
					</div>
				</div>
				<img src="./assets/T2.png" />
				<div class="card">
					<div class="title">问题响应不及时</div>
					<div class="info">
						⼈⼯反映效率低下，问题反馈滞后，影响产线⽣产进度
					</div>
				</div>
				<div class="card">
					<div class="title">质量监控不精确</div>
					<div class="info">
						⼯艺复杂程度⾼，零件数量成百上千，经⼿⼈复杂，责任⽆法精准到物料或者⼈员
					</div>
				</div>
				<img src="./assets/T3.png" />
				<div class="card">
					<div class="title">⼿⼯分析效率低</div>
					<div class="info">
						传统⼿⼯计算⽣产报表效率低下，出错率⾼，影响绩效评估与最终决策
					</div>
				</div>
				<img src="./assets/T4.png" />
			</div>
		</div>
		<div class="advantages">
			<div class="title">方案优势</div>
			<img class="advantagesEn" src="../assets/advantages.png" />
			<div class="advantagesCards">
				<div class="card">
					<div class="text">
						<div class="title">智能决策</div>
						<div class="info">
							系统通过对大量数据的集成和管理，对供应商和企业相关数据从多角度进行统计分析和对比，为企业和供应商领导提供可靠的辅助决策数据分析。
						</div>
					</div>
					<img src="./assets/advantagesImg6.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">全⾯协同制造</div>
						<div class="info">
							通过系统集成，促进实现部门对内部门、对外经销商、供应商、物流承运商的全面制造协同。
						</div>
					</div>
					<img src="./assets/advantagesImg2.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">⽣产运营透明化</div>
						<div class="info">
							实时统计分析生产制造过程核心指标参数，实现运营全过程透明化、智能化管理，为高效率、高质量的智能管理与决策提供支持。
						</div>
					</div>
					<img src="./assets/advantagesImg3.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">设备可视化</div>
						<div class="info">
							MES系统自动接收和维护产品数据、BOM信息、计划排产、设备信息、车间工位信息、物料拉动方式等相关信息,并进行可视化显示，数据信息一目了然。
						</div>
					</div>
					<img src="./assets/advantagesImg4.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">分层管理</div>
						<div class="info">
							强化管理监督，细化审批职责，通过分层审批机制，进而实现质量问题的上传下达。
						</div>
					</div>
					<img src="./assets/advantagesImg5.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">全⾯质量追溯</div>
						<div class="info">
							提供全周期的质量信息历史数据查询，及时发现生产过程中的质量问题。全面管控生产质量、过程质量和产品质量。
						</div>
					</div>
					<img src="./assets/advantagesImg1.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.explain {
	padding-top: 111px;
	width: 1920px;
	height: 442px;
	box-sizing: border-box;
	background: #f3f8fc;
	line-height: 1;
	.title {
		font-size: 42px;
	}
	.info {
		width: 1240px;
		margin: 30px auto 0;
		line-height: 2;
		color: #666666;
		font-size: 16px;
	}
	img {
		width: 848px;
		margin: 0 auto;
		transform: translateY(105px);
	}
}
.painPoints {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.painPointsEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.painPointsCards {
		width: 1240px;
		margin: 80px auto 0;
		display: flex;
		flex-wrap: wrap;
		.card {
			width: 310px;
			height: 310px;
			box-sizing: border-box;
			padding: 40px;
			text-align: left;
			background: #67bd72;
			.title {
				font-size: 22px;
				font-weight: bold;
				color: #ffffff;
				line-height: 1;
				margin-bottom: 30px;
			}
			.info {
				font-size: 18px;
				color: #ffffff;
				line-height: 2;
			}
		}
		.card:nth-of-type(2) {
			background: #2b2644;
		}
		.card:nth-of-type(3) {
			background: #a77eed;
		}
		.card:nth-of-type(4) {
			background: #218aef;
		}
		img {
			width: 310px;
		}
	}
}
.advantages {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.advantagesEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.advantagesCards {
		padding-top: 60px;
		.card {
			padding: 90px;
			display: flex;
			justify-content: space-between;
			position: relative;
			border-top: 1px /*no*/ solid #d5dfe8;
			&:last-of-type {
				border-bottom: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd) {
				border-right: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(even) {
				border-left: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/rightTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				right: -1px;
			}
			&:nth-of-type(even)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/leftTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				left: -1px;
			}
			// &:last-of-type::after {
			//   content: '';
			//   width: 1240px;
			//   height: 1px;/*no*/
			//   background: #D5DFE8;
			//   position: absolute;
			//   bottom: 0;
			//   left: 0;
			// }
			.text {
				padding-top: 43px;
				width: 617px;
				text-align: left;
				.title {
					font-size: 36px;
					line-height: 1;
					margin-bottom: 40px;
				}
				.info {
					font-size: 18px;
					color: #666666;
					line-height: 2;
					display: -webkit-box;
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
				}
			}
			img {
				width: 353px;
			}
		}
		.card:nth-child(even) {
			flex-direction: row-reverse;
		}
		.shadow {
			width: 1240px;
			height: 60px;
			background: linear-gradient(0deg, #ffffff, #f3f8fc);
		}
	}
}
</style>
