<template>
	<div>
		<div class="explain">
			<div class="title">
				轻工纺织
			</div>
			<div class="info">
				<p>轻工纺织行业解决方案是将灵妙自主的信息技术、企业数字化技术与工轻工纺织行业的先进生产技术进行深度融合，<br> 实现纺织产品制造设计、工艺、采购与物流、制造、销售和服务等业务全生命周期的信息化管理。<br> 推动提高企业高效、高产、高质量发展水平。实现轻工纺织企业的数字化、网络化、智能化。</p>
			</div>
		</div>
		<div class="painPoints">
			<div class="title">行业痛点</div>
			<img class="painPointsEn" src="../assets/painPoints.png" />
			<div class="painPointsCards">
				<img src="./assets/T1.png" />
				<div class="card">
					<div class="title">质量管控困难</div>
					<div class="info">
						全行业生产工艺更新快，产出品质量稳定性要求高，传统手工账数据记录成本大，生产过程难追溯、质量问题仍靠印象经验；
					</div>
				</div>
				<img src="./assets/T2.png" />
				<div class="card">
					<div class="title">人员效率难评估</div>
					<div class="info">
						单工序生产周期长，工序内上下工情况复杂，人员效率难评估
					</div>
				</div>
				<div class="card">
					<div class="title">库存物流不透明</div>
					<div class="info">
						库存不透明、更新不及时，不利于制定生产计划 半成品（线边库）现场管控难度大，配单衔接易出错
					</div>
				</div>
				<img src="./assets/T3.png" />
				<div class="card">
					<div class="title">生产周期长但货期短</div>
					<div class="info">
						生产周期长，客户要求货期短，对工厂柔性生产（备货+接单）协作要求高
					</div>
				</div>
				<img src="./assets/T4.png" />
			</div>
		</div>
		<div class="advantages">
			<div class="title">方案优势</div>
			<img class="advantagesEn" src="../assets/advantages.png" />
			<div class="advantagesCards">
				<div class="card">
					<div class="text">
						<div class="title">全面质量追溯</div>
						<div class="info">
							提供全周期的质量信息历史数据查询，及时发现生产过程中的质量问题。全面管控生产质量、过程质量和产品质量。
						</div>
					</div>
					<img src="./assets/advantagesImg1.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">高效绩效考核</div>
						<div class="info">
							通过电子化绩效考核，快速收集车间、产线、工位的生产信息，为绩效分析提供数据支持
						</div>
					</div>
					<img src="./assets/advantagesImg2.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">库存实时透明</div>
						<div class="info">
							通过出入库信息扫描及库内管理，获取实时库存动态，并在中台系统进行分析展示，实现供应链中透明可见。
						</div>
					</div>
					<img src="./assets/advantagesImg3.png" />
				</div>
				<div class="card">
					<div class="text">
						<div class="title">智能决策</div>
						<div class="info">
							系统通过对大量数据的集成和管理，对供应商和企业相关数据从多角度进行统计分析和对比，为企业和供应商领导提供可靠的辅助决策数据分析。
						</div>
					</div>
					<img src="./assets/advantagesImg4.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.explain {
	padding-top: 111px;
	width: 1920px;
	height: 442px;
	box-sizing: border-box;
	background: #f3f8fc;
	line-height: 1;
	.title {
		font-size: 42px;
	}
	.info {
		width: 1240px;
		margin: 30px auto 0;
		line-height: 2;
		color: #666666;
		font-size: 16px;
	}
	img {
		width: 848px;
		margin: 0 auto;
		transform: translateY(105px);
	}
}
.painPoints {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.painPointsEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.painPointsCards {
		width: 1240px;
		margin: 80px auto 0;
		display: flex;
		flex-wrap: wrap;
		.card {
			width: 310px;
			height: 310px;
			box-sizing: border-box;
			padding: 40px;
			text-align: left;
			background: #8E5EDF;
			.title {
				font-size: 22px;
				font-weight: bold;
				color: #ffffff;
				line-height: 1;
				margin-bottom: 30px;
			}
			.info {
				font-size: 18px;
				color: #ffffff;
				line-height: 2;
			}
		}
		.card:nth-of-type(2) {
			background: #493B59;
		}
		.card:nth-of-type(3) {
			background: #8BB22E;
		}
		.card:nth-of-type(4) {
			background: #218AEF;
		}
		img {
			width: 310px;
		}
	}
}
.advantages {
	padding-top: 138px;
	width: 1240px;
	margin: 0 auto;
	position: relative;
	.title {
		font-size: 42px;
		line-height: 1;
		z-index: 1;
		position: relative;
	}
	.advantagesEn {
		width: 475px;
		transform: translateY(-21px);
	}
	.advantagesCards {
		padding-top: 60px;
		.card {
			padding: 90px;
			display: flex;
			justify-content: space-between;
			position: relative;
			border-top: 1px /*no*/ solid #d5dfe8;
			&:last-of-type {
				border-bottom: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd) {
				border-right: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(even) {
				border-left: 1px /*no*/ solid #d5dfe8;
			}
			&:nth-of-type(odd)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/rightTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				right: -1px;
			}
			&:nth-of-type(even)::before {
				content: "";
				width: 16px;
				height: 16px;
				background: url("../assets/leftTopBorder.png");
				background-size: 16px;
				position: absolute;
				top: -1px;
				left: -1px;
			}
			// &:last-of-type::after {
			//   content: '';
			//   width: 1240px;
			//   height: 1px;/*no*/
			//   background: #D5DFE8;
			//   position: absolute;
			//   bottom: 0;
			//   left: 0;
			// }
			.text {
				padding-top: 43px;
				width: 617px;
				text-align: left;
				.title {
					font-size: 36px;
					line-height: 1;
					margin-bottom: 40px;
				}
				.info {
					font-size: 18px;
					color: #666666;
					line-height: 2;
					display: -webkit-box;
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
				}
			}
			img {
				width: 353px;
			}
		}
		.card:nth-child(even) {
			flex-direction: row-reverse;
		}
		.shadow {
			width: 1240px;
			height: 60px;
			background: linear-gradient(0deg, #ffffff, #f3f8fc);
		}
	}
}
</style>
