<template>
	<div class="page">
		<lm-header :scrollTop="scrollTop" :bannerH="bannerH" :navH="navH" replaceHeader />
		<div ref="banner" class="banner">
			<div class="layout content">
				<div class="title">灵妙数字化解决方案</div>
				<div class="info">降本增效，助力企业数字化转型</div>
				<div class="button" @click="() => {$router.push('/relation')}">立即体验</div>
			</div>
		</div>
		<div ref="nav" class="navBox" :style="navStyle">
			<div class="nav">
				<div
					ref="nav0"
					class="title"
					:class="{ active: navActive == '0' }"
					@click="navActiveChange('0')"
				>
					汽车零部件制造行业
				</div>
				<div
					ref="nav1"
					class="title"
					:class="{ active: navActive == 1 }"
					@click="navActiveChange(1)"
				>
					电子组装行业
				</div>
				<div
					ref="nav2"
					class="title"
					:class="{ active: navActive == 2 }"
					@click="navActiveChange(2)"
				>
					机械工程行业
				</div>
				<div
					ref="nav3"
					class="title"
					:class="{ active: navActive == 3 }"
					@click="navActiveChange(3)"
				>
					轻工纺织行业
				</div>
				<div
					ref="nav4"
					class="title"
					:class="{ active: navActive == 4 }"
					@click="navActiveChange(4)"
				>
					数字化咨询&定制开发
				</div>
				<div class="activebox" :style="activeStyle">
					<div></div>
				</div>
			</div>
		</div>
		<div v-show="this.scrollTop >= this.bannerH" style="width:100px;height:80px;"></div>
		<div v-if="navActive == 0" class="view1">
			<view1 />
			<scheme-function key="scheme-function1" show="1,2,3,4,5,6,7,8,9" />
		</div>
		<div v-else-if="navActive == 1" class="view1">
			<view2 />
			<scheme-function key="scheme-function2" show="1,3,4,6,7,8,9" />
		</div>
		<div v-else-if="navActive == 2" class="view1">
			<view3 />
			<scheme-function key="scheme-function3" show="1,2,3,4,6,7,8,9" />
		</div>
		<div v-else-if="navActive == 3" class="view1">
			<view4 />
			<scheme-function key="scheme-function4" show="1,2,3,4,5,6,7,8,9" />
		</div>
		<div v-else-if="navActive == 4" class="view5">
			<div class="consulting">
				<div class="title">数字化转型咨询</div>
				<div class="info">
					<p>围绕企业数字化应用转型阶段，灵妙可通过数字化系统组建、指标布建、管理改善以及团建支撑等程序与手段，<br> 帮助企业经营者、管理层与使用人员体验价值服务，并提升各阶段不同的应用价值，借助数字化企业服务启动数字化转型之路。<br> 通过咨询、实施和优化一体化流程服务，走好数字化转型每一步。</p>
				</div>
				<router-link tag="div" to="/relation" class="button pointer">立即咨询</router-link>
				<img src="./assets/数字化转型咨询pt@2x.png">
				<div class="shdow"></div>
			</div>
			<div class="shadow"></div>
			<div class="development">
				<div class="title">定制开发</div>
				<router-link tag="div" to="/relation" class="button pointer">立即定制</router-link>
				<div class="step">
					<div>
						<img src="./assets/step1@2x.png" >
						<div class="info">专业的咨询顾问<br> 服务对接</div>
					</div>
					<div class="line"></div>
					<div>
						<img src="./assets/step2@2x.png" >
						<div class="info">全⾯调研企业的<br> ⽬标与现状</div>
					</div>
					<div class="line"></div>
					<div>
						<img src="./assets/step3@2x.png" >
						<div class="info">梳理业务流程<br> 形成⽅案</div>
					</div>
					<div class="line"></div>
					<div>
						<img src="./assets/step4@2x.png" >
						<div class="info">构建流程架构<br> 落地实施</div>
					</div>
					<div class="line"></div>
					<div>
						<img src="./assets/step5@2x.png" >
						<div class="info">跟踪反馈<br> 细节优化</div>
					</div>
				</div>
			</div>
		</div>
		<lm-footer />
	</div>
</template>

<script>
  import LmHeader from "@/components/header";
  import LmFooter from "@/components/footer";
	import View1 from './view1';
	import View2 from './view2';
	import View3 from './view3';
	import View4 from './view4';
	import SchemeFunction from './schemeFunction';
  export default {
    components: {
      LmHeader,
      LmFooter,
			View1,
			View2,
			View3,
			View4,
			SchemeFunction
    },
    computed: {
      activeStyle() {
        const style = {};
        style.width = this.navWidth[this.navActive] + "px";
        style.left = this.navLeft[this.navActive] + "px";
        return style;
      },
			navStyle() {
        let data = {}
        if (this.scrollTop >= this.bannerH) data = {
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 100
        }
        else data = {}
        return data
      }
    },
    data() {
      return {
        scrollTop: 0,
        navActive: null,
        navWidth: [],
        navLeft: [],
        bannerH: 450,
        navH: 40
      };
    },
    mounted() {
      this.bannerH = this.$refs.banner.offsetHeight;
      this.navH = this.$refs.nav.offsetHeight;
      window.addEventListener("scroll", this.getScroll);
      this.navWidth = [
        this.$refs.nav0.clientWidth,
        this.$refs.nav1.clientWidth,
        this.$refs.nav2.clientWidth,
        this.$refs.nav3.clientWidth,
        this.$refs.nav4.clientWidth,
      ];
      this.navLeft = [
        this.$refs.nav0.offsetLeft,
        this.$refs.nav1.offsetLeft,
        this.$refs.nav2.offsetLeft,
        this.$refs.nav3.offsetLeft,
        this.$refs.nav4.offsetLeft,
      ];
			if(this.$route.params.index) {
				this.navActive = this.$route.params.index;
			} else {
				this.navActive = 0;
			}
			this.getScroll();
    },
    methods: {
      getScroll() {
        this.scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      },
			navActiveChange(index) {
				if (this.scrollTop > this.bannerH) document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
				this.navActive = index;
			}
    },
  };
</script>

<style lang="less" scoped>
.banner {
	width: 1920px;
	height: 450px;
	background: url("./assets/banner.png") no-repeat;
	background-size: 1920px;
	position: relative;
	.content {
		padding-top: 155px;
		line-height: 1;
		text-align: left;
		color: #ffffff;
		.title {
			font-size: 50px;
			padding-bottom: 30px;
		}
		.info {
			font-size: 22px;
			padding-bottom: 53px;
		}
		.button {
			display: inline-block;
			font-size: 18px;
			padding: 15px 34px;
			background: #ff9000;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.navBox {
	width: 100vw;
	background-color: white;
	box-shadow: 0px 6px 20px 0px rgba(142, 171, 201, 0.12);
	.nav {
		width: 1240px;
		height: 80px;
		margin: 0 auto;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		position: relative;
		.title {
			padding-top: 30px;
			cursor: pointer;
			transition: all 0.5s;
			&.active {
				color: #218aef;
			}
			&:hover {
				color: #218aef;
			}
		}
		.activebox {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 3px;
			background: #218aef;
			min-width: 100px;
			transition: all 0.5s;
			text-align: center;
			div {
				display: inline-block;
				width: 55%;
				height: 20px;
				background: linear-gradient(to bottom, #ffffff, #d3ebff);
				transform: translateY(-20px);
			}
		}
	}
}

.view5 {
	width: 1240px;
	margin: 0 auto;
	.consulting {
		margin-top: 110px;
		.title {
			font-size: 42px;
			line-height: 1;
		}
		.info {
			font-size: 18px;
			line-height: 2;
			margin: 30px 0 42px;
			color: #666666;
		}
		.button {
			padding: 10px 18px;
			font-size: 16px;
			color: #FFFFFF;
			background: #3A9EFF;
			display: inline-block;
			border-radius: 4px;
		}
		img {
			display: block;
			margin: 0 auto;
			width: 860px;
		}
		.shadow {
			width: 1240px;
			height: 60px;
			background: linear-gradient(to bottom, #F3F8FC, #FFFFFF);
		}
	}
	.shadow {
		margin-top: 40px;
		height: 60px;
		background: linear-gradient(0deg, #FFFFFF, #F3F8FC);
	}
	.development {
		margin: 70px auto 100px;
		.title {
			font-size: 42px;
			line-height: 1;
			margin-bottom: 39px;
		}
		.button {
			padding: 10px 18px;
			font-size: 16px;
			color: #FFFFFF;
			background: #3A9EFF;
			display: inline-block;
			border-radius: 4px;
		}
		.step {
			margin-top: 70px;
			width: 1240px;
			display: flex;
			justify-content: space-between;
			.line {
				width: 30px;
				height: 2px;
				background-color: #D6E2EA;
				margin-top: 125px;
			}
			div {
				width: 152px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				img {
					width: 152px;
					margin-bottom: 30px;
				}
				.info {
					text-align: left;
					font-size: 18px;
					line-height: 2;
				}
			}
		}
	}
}
</style>
